<template>
  <div class="general">
      <smallbutton toplabel="Example" @click="Add()" buttonclass="buttonsmaller buttonprimary"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal" > 
        <template v-slot:body>
                <div class="rightjustified">
                    <smallbutton @click="close()" toplabel='X' buttonclass="buttonclose" />
                </div>
                <div class="vertFlex margintop" >
 
                    <label for="">{{message1}}</label>
                    <br>
                    <label for="">{{message2}}</label>
                    <div >
                        <smallbutton  @click="cancelupdate()" toplabel="Close" />
                    </div>
                </div>

                <div>
                    <label class="responsemessage">{{message}}</label>
               </div>
       
        </template>
      </actionmodal>
      
  </div>
</template>

<script>


import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'

export default {
    name: 'alerteexample',
    components:{
        smallbutton,
        actionmodal,

    },
    props:{
        message1:{
            type:String,
        },
        message2:{
            type:String,
        },
        
    },

    data () {
        return {
            
            message:'',

            

        }
    },
    computed:{
       
    },
    methods:{
        Add(){
            this.$refs.mymodal.openModal()
        },
        close(){
            this.$refs.mymodal.closeModal()
        },
        
        cancelupdate(){
            this.$refs.mymodal.closeModal()
        },
        
    }
}
</script>

<style>
.general{
    font-size:medium;
}
.smalltext{
    font-size:small;
}
.margintop{
    margin-top:20px;
}
.fview{
    background-color: white;
    padding:5px;
    text-align: left;
}
textarea{
    visibility: collapse;
    height: 0px;
}
</style>