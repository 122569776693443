
import {HTTP} from './repository'
import {store} from './store/store'
export default{
    methods:{
        AddTokenToAPI(token){
            
            HTTP.defaults.headers.post['token']=token
        },
        getFailureResponse(message){
            
            if(message.includes('401')){
                message=''//'You are not authorized. Please check your login credentials.'
                store.dispatch('failedAuthLogout');
            }
            return {
                'Successful':false,
                'Message':message
            }
        },
        async callAPI(req,method){
            try{
                var response = await HTTP.post(method,req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                return response.data
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        }, 
    },
    
}