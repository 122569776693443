<template>
  <div class="loginDiv">
       <div v-if="didFailAuth" class="bottom10">
            <label class="responsemessage">You have been securely logged out. Please login again to continue.</label>
        </div>
        <div v-if="!isGoogle">
            <form ref="loginform" v-on:keyup.enter="login">
                <formentrystacked inputid="user" label="email" v-model="username" />
                <formentrystacked inputid="pass" inputtype="password" label="password" v-model="password" />
                
                <smallbutton @click="login()" toplabel="Login"/>
                
                <div v-if="pleaseWait">
                    <label for="" class="pleasewait">Please Wait...</label>
                </div> 
            </form>
            <div class="margin10">
                <linkbutton @click="forgotpassword()" toplabel="forgot password"/>
            </div>
        </div>
            
        <!-- GOOGLE -->
        <div v-if="isGoogle">
            <googleidentity @loggedin="googleloggedin" @loginfailed="googlefailed"/>
            <!-- <googlelogin @loggedin="googleloggedin" @loginfailed="googlefailed"/> -->
        </div>
        <div class="horizFlex " v-if="allowStoreLogin">
            <input class="entryCheckbox" type="checkbox" v-model="storeLogin" >
            <label for="" class="mediumtext">Keep Me Logged In</label>
        </div>
        <div>
            <label class="responsemessage">{{responseMessage}}</label>
        </div>
        


      <updatepassword ref="changepass" v-show="ischangepassword" :username="username" :oldpassword="oldpassword"></updatepassword>
    </div>

        
</template>

<script>
import {mapFields} from 'vuex-map-fields'

import datamethods from '../../datamethods.js'
import formentrystacked from '../controls/entry-stacked.vue'
import linkbutton from '../controls/linkbutton.vue'
import smallbutton from '../controls/button.vue'
import updatepassword from './updatepassword.vue'
import googleidentity from './googleidentity.vue'
export default {
    name:'loginuser',
    mixins:[datamethods],
    components:{
        formentrystacked,
        linkbutton,
        smallbutton,
        updatepassword,
        googleidentity
    },
    computed:{
        ...mapFields([
            'loggedInUser',
            'loggedIn',
            'username',
            'oldpassword',
            'didFailAuth'
        ]),
        
    },
    data(){
        return{
            newUserURL:window.NEW_USER_URL,
            newUserText:'Click here to create a new Account',
            password:'',

            pleaseWait:false,
            responseMessage:'',

            ischangepassword:false,

            //GOOGLE
            isGoogle:window.GOOGLE_LOGIN!="",

            token:null,
            allowStoreLogin:true,
            storeLogin:false,
            isMountedLogin:true
        }
    },
    mounted(){
    
    
  },
    methods:{
        prelogin(){
            if(this.allowStoreLogin){
        
      
                this.token = localStorage.token
                //console.log('t' + this.token)
                if(this.token==null){return }
                if(this.token=='' || this.token=='null'){return}

                //now we call our login, with the token! (no username or pwd)
                this.post_login(this.token)
                
            }
        },
        changepassword(){
            this.ischangepassword=true
            this.$refs.changepass.ChangePassword();
        },
        async forgotpassword(){
            
            //validate
            if(this.username==""){
                return this.getFailureResponse("Please enter your email address and click forgot password")
              }
            let req = {
                email:this.username,
            }
            this.pleaseWait=true;
            this.responseMessage='';
            var response = await this.callAPI(req,'forgotpass_appurl')
            //handle response
            if(response.Successful){
                 this.responseMessage = "Please check your email for your password reset link"
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;    
        },
        /* async login(){
            this.responseMessage='';
            //validate
            if(this.username=="" || this.password==""){return}
            let req = {
                emailAddress:this.username,
                password:this.password,
            }
            var response = await this.callAPI(req,'badgelogin')
            //handle response
            if(response.Successful){
                console.log(response)
                this.loggedInUser= response.Person
                this.loggedIn=true
                this.$emit('loggedin')
                //this.AddUserToAPI(this.loggedInUser)
               
            }else{
                this.responseMessage = response.Message
            }
           
        }, */
        async login(){
            this.responseMessage='';
            //validate
            if(this.username=="" || this.password==""){return}
            let req = {
                username:this.username,
                password:this.password,
            }
            var response = await this.callAPI(req,'securitylogin')
            //handle response
            if(response.Successful){
                this.post_login(response.securityInfo.token)
            }else{
                this.responseMessage = response.Message
                this.loggedIn=false
            }
           
        },
        //GOOGLE HANDLING
        googleloggedin(token){
            this.post_login(token)
        },
        googlefailed(message){
            this.responseMessage =message
        },
        async post_login(token){
            //add to api
            this.AddTokenToAPI(token)

            //store?
            if(this.storeLogin){
                localStorage.token=token;
            }

            //no username/pwd
            this.responseMessage='';
            //validate
            var response = await this.callAPI(null,'badgelogin_admin')
            //handle response
            if(response.Successful){
                console.log(response)
                this.loggedInUser= response.Person
                this.loggedIn=true
                this.didFailAuth=false
                this.$emit('loggedin')
                //this.AddUserToAPI(this.loggedInUser)
               
            }else{
                this.loggedIn=false
                //don't show the message on the mounted login
                if(this.isMountedLogin){
                    this.isMountedLogin=false
                }else{
                    this.responseMessage = response.Message
                }
                
            }
        }
       
    }
}
</script>

<style >

.loginDiv{
  margin-top:60px;
  grid-area: center;
  display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size:large;
}
.margin10{
    margin:10px;
}
</style>


