<template>
  <div class="general">
      <smallbutton toplabel="Example" @click="Add()" buttonclass="buttonsmaller buttonprimary"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal" > 
        <template v-slot:body>
                <div class="rightjustified">
                    <smallbutton @click="close()" toplabel='X' buttonclass="buttonclose" />
                </div>
                <div class="vertFlex margintop" >
 
                    <div class="fview" >
                        <froalaView v-model="htmlWithSample" />
                    </div>

                    <div >
                        <smallbutton  @click="cancelupdate()" toplabel="Close" />
                    </div>
                </div>

                <div>
                    <label class="responsemessage">{{message}}</label>
               </div>
       
        </template>
      </actionmodal>
      
  </div>
</template>

<script>
 import VueFroala from 'vue-froala-wysiwyg';
import FroalaEditor from 'froala-editor'


import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'
import lookupviewlayout from '../layouts/lookupviewlayout.vue'
import lookupresult from '../controls/lookupresult.vue'
import entry from '../controls/entry.vue'
import datamethods from '../../datamethods.js'

export default {
    name: 'messageteexample',
    components:{
        smallbutton,
        actionmodal,
        lookupviewlayout,
        lookupresult,
        entry
    },
    props:{
        templateId:{
            type:Number,
        },
  
        
    },
    mixins:[datamethods],
    data () {
        return {
            /* config:{
                key: window.FROALA_KEY,
                toolbarButtons: ["bold", "italic"]

            }, */
            config: {
                height:420,
                key:window.FROALA_KEY,
                events: {
                    'froalaEditor.initialized': function () {
                        console.log('initialized')
                    },
  
                },
                toolbarButtons:[['undo','redo','selectAll'],
                ['bold','italic','underline','subscript','superscript','fontFamily','fontSize','textColor','backgroundColor','clearFormatting'],
                ['alignLeft','alignCenter','alignRight','alignJustify'],
                ['insertLink','emoticons','specialCharacters','insertHR'],['print']],
            
            },
            message:'',

            selectedResultID:0,
            lookupResults:[],
            selectedResult:{
                id:0,
                name:'',
                html:'',
                plain:'',
                sms:'',
                subject:'',
                IsActive:true,
                tokenList:[]
            },
            baseButtons:[['undo','redo','selectAll'],
                ['bold','italic','underline','subscript','superscript','fontFamily','fontSize','textColor','backgroundColor','clearFormatting'],
                ['alignLeft','alignCenter','alignRight','alignJustify'],
                ['insertLink','emoticons','specialCharacters','insertHR'],['print']],
            addedButtons:[],
            inUpdate:false,
            showEdit:false,

        }
    },
    computed:{
        ToggleText(){
            if(this.showEdit){
                return "Show Example"
            }else{
                return "Hide Example"
            }
        },
        htmlWithSample(){
            var out = this.selectedResult.html
            console.log(out)
            out = out.replaceAll('[EmailAddress]','test@schoolvision.net')
            out = out.replaceAll('[FirstName]','Test')
            out = out.replaceAll('[LastName]','User')
            out = out.replaceAll('[BadgeNumber]','123456')
            console.log(out)
            return out
        },
    },
    methods:{
        close(){
            this.$emit('refresh')
            this.$refs.mymodal.closeModal()
        },
        toggleSample(){
            this.showEdit=!this.showEdit
        },
        select(result){
            this.selectedResult=result;
            this.selectedResultID=result.id;
            
        },
        async Add(){
            this.getTemplates()
            
            
        },
        addToolBarInsert(bname,btext){
            FroalaEditor.DefineIcon('insert', {NAME: 'plus', SVG_KEY: 'add'});
            FroalaEditor.RegisterCommand(bname, {
                title: bname,
                focus: true,
                undo: true,
                refreshAfterCallback: true,
                callback: function () {
                this.html.insert(btext);
                }
            });
            var newButton = [bname]
            console.log('what im adding ' + bname + ' ' +  newButton + ' ' + btext)
            this.config.toolbarButtons.push(newButton)

        },
        async getTemplates(){
            let req = {
                MessageTemplate:{
                    id:this.templateId
                }
            }
            var response = await this.callAPI(req,'template')
            if(response.Successful){
                console.log(response)
                
                this.selectedResult=response.MessageTemplateList[0]
                this.AddToolbarButtons()
                this.$refs.mymodal.openModal()
            }else{
                //this.message = response.Message
            }

        },
        update(){
            this.inUpdate=true;
            this.showEdit=true;
        },
        cancelupdate(){
            this.$refs.mymodal.closeModal()
        },
        async saveNew(){
            let req = {MessageTemplate: this.selectedResult}
            
            var response = await this.callAPI(req,'savetemplate')
            if(response.Successful){
                console.log(response)
                
               // this.lookupResults=response.MessageTemplateList
                
                
            }else{
                //this.message = response.Message
            }
            this.inUpdate=false;
        },
        AddToolbarButtons(){
           // this.addToolBarInsert("insert this!!","[this]")

            for(let index = 0; index < this.selectedResult.tokenList.length; ++index){
                const t = this.selectedResult.tokenList[index]
                this.addToolBarInsert(t.name,t.tagtext)
            } 
             
            
        }
    }
}
</script>

<style>
.general{
    font-size:medium;
}
.smalltext{
    font-size:small;
}
.margintop{
    margin-top:20px;
}
.fview{
    background-color: white;
    padding:5px;
    text-align: left;
}
textarea{
    visibility: collapse;
    height: 0px;
}
</style>